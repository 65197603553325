import React from "react"
import MainLayout from '../layouts/MainLayout'
import HomeSwiper from '../components/home-page/HomeSwiper'
import Section2 from "../components/home-page/Section2"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import seoContents from "../constants/seo-contents"

const Home = ({ data }) => {
  return (
    <MainLayout>
      <SEO title={`${seoContents.homePage.title}`} description={`${seoContents.homePage.description}`} />
      <HomeSwiper sources={data.strapiSliders} />
      <Section2 />
    </MainLayout>
  )
}

export default Home

export const pageQuery = graphql`  
  query {
    strapiSliders(PageType: {eq: "HomePage"}) {
      id
      Sliders {
        Content
        DesktopImage {
          localFile{
            publicURL
          }
        }
        MobileImage {
          localFile{
            publicURL
          }
        }
        Location
      }
    }
  }
`
