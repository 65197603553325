import { Link } from "gatsby"
import React from "react"

const Section2 = () => {
  return (
    <section className="home-page-section-2">
      <div className="home-page-section-2-center">
        <article>
          ‘We are at a moment in the human story where we must come together as a global family and embrace peace and sustainability. The first step to achieving deep and lasting peace is to fill empty stomachs and, since April 2020, Peace Train has provided almost FOUR MILLION MEALS in the UK and overseas. With your help, we can provide even more meals to those in desperate need so donate now and jump upon the Peace Train!’ <br />
          <br />
          <span>Everyone jump upon the peace train!</span>
        </article>
      </div>
      {/* <div style={{
        display: "flex",
        justifyContent: "center", paddingBottom: 20
      }}>
        <Link className="btn btn-primary" to="/TurkeyGreeceForestFiresFund"
          style={{
            textAlign: "center",
            fontSize: 25,
            marginTop: "1.5rem",
            marginBottom: "auto",
            display: "flex",
            justifyContent: "center",
            marginLeft: 15,
            marginRight: 15,
            height: 70,
            textAlign: "center",
            alignItems: "center"
          }}>
          <span>Latest Campaign - Forest Fire Fund</span>
        </Link>
      </div> */}
      <div className="home-page-buttons">
        <div><br /></div>
        <Link className="btn btn-primary" to="/about-us">
          <span>about us</span>
        </Link>
        <Link className="btn btn-primary" to="/stay-in-touch">
          <span>get on board</span>
        </Link>
        <div><br /></div>
      </div>
    </section >
  )
}

export default Section2
