import React from 'react'

import 'swiper/swiper.scss'
import Swiper from "react-id-swiper"

import NextIcon from "../../assets/images/navigate_next.svg"
import PrevIcon from "../../assets/images/navigate_prev.svg"

const sliderParams = {
    slidesPerView: "auto",
    autoplay: {
        delay: 5000,
        disableOnInteraction: true
    },
    loop: true,
    ContainerEl: 'section',
    WrapperEl: 'section',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    renderNextButton: () => <div className="swiper-button-next swiper-button swiper-button-Next"><img src={PrevIcon} alt="NextIcon" /></div>,
    spaceBetween: 30,
    containerClass: "home-page-slider",
    wrapperClass: "homeSwipperWrapper"
}

function HomeSwiper({ sources }) {
    return (
        <Swiper
            {...sliderParams}
            allowSlideNext={(sources && sources.Sliders.length > 1) ? true : false}
            allowSlidePrev={(sources && sources.Sliders.length > 1) ? true : false}
            renderPrevButton={() => { if (sources && sources.Sliders.length > 1) { return (<div className="swiper-button-prev swiper-button swiper-button-Prev"><img src={NextIcon} alt="PervIcon" /></div>) } else { return null } }}
            renderNextButton={() => { if (sources && sources.Sliders.length > 1) { return (<div className="swiper-button-next swiper-button swiper-button-Next"><img src={PrevIcon} alt="NextIcon" /></div>) } else { return null } }}
        >
            {sources && sources.Sliders.map((slider, index) => {
                return (
                    <div>
                        <div
                            style={{ backgroundImage: `url('${slider.DesktopImage.localFile.publicURL}')` }}
                            key={index}
                            className="banner-img img-desktop"
                        >
                            <h2>{slider.Content}</h2>
                            <p>{slider.Location}</p>
                        </div>
                        <div
                            style={{ backgroundImage: `url('${slider.MobileImage.localFile.publicURL}')` }}
                            key={index}
                            className="banner-img img-mobile"
                        >
                            <h2>{slider.Content}</h2>
                            <p>{slider.Location}</p>
                        </div>
                    </div>
                )
            })}
        </Swiper>
    )
}

export default HomeSwiper
